import React, { Component } from 'react'
import { Link } from "react-router-dom";
import logo from '../../assets/img/logo.svg'
import '../../assets/css/header.css'
import SearchBar from './SearchBar'
export default class Header extends Component {
    render() {
        return (
            <header>
                <div className="container">
                <div  className="header-wrapper">
                <div className="brand-name">
                      <Link to="/"> <img src={logo} alt="logo" /></Link> 
                    </div>
                    <div className="menu">
                       <SearchBar/>
                        <Link to="/" className="btn transparent-btn mg-right10">Worldwide</Link>
                       
                    </div>
                </div>
                </div>
            </header>
        )
    }
}
