import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { FiHome} from 'react-icons/fi';
export default class NotFound extends Component {
    render() {
        return (
            <div className="page-not-found-wrapper">
            <div className="page-not-found">
            <div className="not-found-content">
              <h1>404 </h1>
            </div>
               
                <Link to="/" className="btn transparent-btn"><FiHome/> Back To Home</Link>
            </div>
               
            </div>
        )
    }
}
