import React, { Component } from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import axios from 'axios'
import { withRouter } from 'react-router-dom';

 class SearchBar extends Component {
    constructor(props){
        super(props);
        this.state={
            items:[],
            searchItems:[]
        }
    }

    myFunction = (item, index) => {
        this.setState({
            searchItems: this.state.searchItems.concat({ 'id': index, 'name': item.name}),
        })
    }
    fetchCountryCity = () => {

        axios.get("/api/twitter/all-country-city", {
        }).then(res => {
        const dataItems= res.data;
            this.setState({
                items: res.data,
            });
            dataItems.forEach(this.myFunction)
        }).catch(err => console.log(err))
    }
    // handleOnSearch = (string, results) => {
    //     // onSearch will have as the first callback parameter
    //     // the string searched and for the second the results.
    //     console.log(string, results)

    //   }
    
    //   handleOnHover = (result) => {
    //     // the item hovered
    //     console.log(result)
    //   }
    
      handleOnSelect = (item) => {
        // the item selected
      //  console.log(item.name)
        this.props.history.push("/" + item.name);
      }
    
    //   handleOnFocus = () => {
    //     console.log('Focused')
    //   }
componentDidMount(){
    this.fetchCountryCity()
}
    render() {
        const {searchItems} = this.state;
 
        return (
            <div className="search-bar-wrapper">

            <ReactSearchAutocomplete
              items={searchItems}
            //   onSearch={this.handleOnSearch}
            //   onHover={this.handleOnHover}
              onSelect={this.handleOnSelect}
            //   onFocus={this.handleOnFocus}
              placeholder="Search your country or city"
              autoFocus
            />
          </div>
        )
    }
}
export default withRouter(SearchBar);