import React, {useState} from 'react'
import { FiCopy} from 'react-icons/fi';





// const copyText =(value) =>{
//     navigator.clipboard.writeText(value)
// }


const TrendsItems = ({item, sl }) => {
    const [showCopiedIcon, setIcon] = useState(false);
    const tweetVolume = parseInt(item.tweet_volume);
    const onLaunchClicked = (val) => {
        navigator.clipboard.writeText(val)
        setIcon(true);
    
        // **** here's the timeout ****
        setTimeout(() => setIcon(false), 3000);
    
      //  return onLaunchClicked();
    
    }
    return (
        <div className="trends-item">
        <div className="sl-denote">{sl+1 }</div>
        <div className="trends-item-name">
            <h3><a href={ item.url } target="_blank" rel="noreferrer">{ item.name }</a></h3>
            <span>{ isNaN(tweetVolume) ? "Under 10" : tweetVolume < 10000 ? null : (tweetVolume/1000).toFixed(1) }K tweet</span>
        </div>
        <div className="tweet-copy"><button className="btn primary-btn" onClick={() =>onLaunchClicked(item.name)}> <FiCopy/> { showCopiedIcon ? 'Copied' : 'Copy' } </button></div>
    </div>
    )
}

export default TrendsItems
