import React, { Component } from 'react'
import '../../assets/css/footer.css'
import { Link } from "react-router-dom";
import { FiFacebook, FiTwitter, FiInstagram , FiLinkedin} from 'react-icons/fi';
export default class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="container">
                    <div className="footer-top">
                        <div className="row">
                            <div className="col-md-3 border-right">
                               <ul className="footer-links">
                                  <li><Link to="/">Genres</Link></li>
                                  <li><Link to="/">Language</Link></li>
                                  <li><Link to="/">Most Read</Link></li>
                                  <li><Link to="/">Popular</Link></li>
                                  <li><Link to="/">Short Stories</Link></li>
                               </ul>
                            </div>
                            <div className="col-md-3 border-right">
                            <ul className="footer-links">
                                  <li><Link to="/">Writter</Link></li>
                                  <li><Link to="/">Location</Link></li>
                                  <li><Link to="/">Kalpnic Story</Link></li>
                                  <li><Link to="/">Upload</Link></li>
                               </ul>
                            </div>
                            <div className="col-md-3 border-right">
                            <ul className="footer-links">
                                  <li><Link to="/">About</Link></li>
                                  <li><Link to="/">Contact</Link></li>
                                  <li><Link to="/">Terms & Condition</Link></li>
                               </ul>
                            </div>
                            <div className="col-md-3">
                            <div className="social-link">
                              <h3>Follow</h3>
                              <ul>
                                  <li><Link to="/"><FiFacebook/></Link></li>
                                  <li><Link to="/"><FiTwitter/></Link></li>
                                  <li><Link to="/"><FiInstagram/></Link></li>
                                  <li><Link to="/"><FiLinkedin/></Link></li>   
                               </ul>
                            </div>
                               
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">  
                         <p>@2021 Kalpnic story. All right reserved  | <Link to="/">Privacy</Link> | <Link to="/">Terms of Service</Link></p>
                     </div>
                </div>
            </footer>
        )
    }
}
