import { BrowserRouter, Switch, Route } from "react-router-dom";

//common sections
import Header from './components/common/Header'
import Footer from './components/common/Footer'

//Pages 
import Home from './components/pages/Home'
import About from './components/pages/About'
import SearchTrends from './components/pages/SearchTrends'
import NotFound from './components/pages/NotFound'


function App() {
  return (
    <BrowserRouter>
       <Header/>
         <Switch>
            <Route exact path="/" component={Home} />
            <Route  path="/about" component={About} />
            <Route path="/not-found" component={NotFound} />
            <Route path="/:country" component={SearchTrends} />
           
            <Route exact component={NotFound} />
            
            
          </Switch>
       <Footer/>
    </BrowserRouter>
  );
}

export default App;