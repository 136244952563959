import React, { Component } from 'react'
import axios from 'axios'
import TrendsItems from './trends/TrendsItems'
import LoaderImg from '../../assets/img/loader.gif'
import { Link } from "react-router-dom";

export default class SearchTrends extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trends:[],
            cities:[],
            trendslimit: 10,
            citiesLimit:15,
            location:null,
            isLoading:true
        }
    }



    onLoadMore = () => {
        this.setState({
            trendslimit: this.state.trendslimit + 10
        });
    }
    onLoadMoreCity = () => {
        this.setState({
            citiesLimit: this.state.citiesLimit + 10
        });

    }
    
    getTrendsByCountry = (country) =>{
        axios.get(`/api/twitter/trends/${country}`, {
        }).then(res => {
            if(res.data.success === false){
                return this.props.history.push("/not-found");
            }
            if(res.data.success === true){
            this.setState({
                trends: res.data.trendsArray[0].trends,
                 location: res.data.trendsArray[0].locations[0].name,
                 cities:res.data.cities,
                isLoading: false
            })
        }
        }).catch(err => console.log(err))
    }

    componentDidMount() {
        var country = this.props.match.params.country;
       this.getTrendsByCountry(country);
    }
    componentDidUpdate(prevProps) {

        if (prevProps.match.params !== this.props.match.params) {
          this.setState({
            isLoading: true,
            trendslimit: 10,
          });
          var country = this.props.match.params.country;
          this.getTrendsByCountry(country);
        }
      }
    render() {
        const { trends, trendslimit, location,cities,citiesLimit,isLoading } = this.state;
        const showTrends = trends.slice(0, trendslimit);
        const showCities = cities.slice(0, citiesLimit);
        
        return (
            <>
            <div className="banner">
                <div className="container">
                    <h1>Top Twitter trends in {!isLoading ? location : null} now</h1>
                </div>
            </div>
            <div className="trends-section">
                <div className="container trends-list">
                    <div className="row">
                        <div className="col-md-9">

                            {
                                isLoading ? <div className="data-loading"><img src={ LoaderImg } alt=""/> Data Loadding... </div> :
                                    showTrends.map((item, index) => {
                                        return <TrendsItems key={index} item={item} sl={index} />
                                    })
                            }
{
    isLoading ? null :<div className="load_more">
                                {showTrends.length === trends.length ? null : <button onClick={this.onLoadMore} className="btn primary-btn"> Load more.. </button>}
                            </div>
}
                            
                        </div>
                        <div className="col-md-3 border-left">
                            <div className="sidebar">
                         { !isLoading && cities.length !== 0 ? <h3>Cities</h3> : null }
                                    <ul className="sidebar-link">
                                        {
                                            isLoading ?<div className="data-loading"><img src={ LoaderImg } alt=""/> Data Loadding... </div>:
                                            cities.length !== 0 ?
                                            showCities.map((item, index) => {
                                                    return <li key={index}><Link to={`/` + item.name} >{item.name}</Link></li>

                                                }): 
                                                <div className="citiy-not-found">
                                                <p> City not availble</p>
                                                   <button onClick={this.props.history.goBack}>Back</button>
                                                </div>    
                                        }

                                    </ul>
                                    
                                    <div className="load_more">
                                {showCities.length === cities.length ? null : <button onClick={this.onLoadMoreCity} className="read-more-link"> Load more.. </button>}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }
}
