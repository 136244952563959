import React, { Component } from 'react'
import '../../assets/css/main.css'
import { Link } from "react-router-dom";
import TrendsItems from './trends/TrendsItems'
import axios from 'axios'
import LoaderImg from '../../assets/img/loader.gif'
export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTrendLoading: true,
            isCountryLoading: true,
            trendslimit: 10,
            countrylimit: 15,
            trends: [],
            countries: null,
            location: null
        }
    }

    onLoadMore = () => {
        this.setState({
            trendslimit: this.state.trendslimit + 10
        });

    }
    onLoadMoreCountry = () => {
        this.setState({
            countrylimit: this.state.countrylimit + 15
        });

    }
    fetchTrends = () => {

        axios.get("/api/twitter/trends", {
        }).then(res => {
            this.setState({
                trends: res.data[0].trends,
                location: res.data[0].locations[0].name,
                isTrendLoading: false
            })
        }).catch(err => console.log(err))
    }

    fetchCountry = () => {

        axios.get("/api/twitter/all/countries", {
        }).then(res => {
            this.setState({
                countries: res.data,
                isCountryLoading: false
            })
        }).catch(err => console.log(err))
    }

    componentDidMount() {
        this.fetchTrends();
        this.fetchCountry();
    }

    render() {
        const { isTrendLoading, isCountryLoading, countries, trends, location, trendslimit, countrylimit } = this.state;
        const showTrends = trends.slice(0, trendslimit);
        const showCountry = countries ? countries.slice(0, countrylimit) : null;

        return (
            <>
                <div className="banner">
                    <div className="container">
                        <h1>Top Twitter trends in {!isTrendLoading ? location : null} now</h1>
                    </div>
                </div>
                <div className="trends-section">
                    <div className="container trends-list">
                        <div className="row">
                            <div className="col-md-9">

                                {
                                    isTrendLoading ? <div className="data-loading"><img src={ LoaderImg } alt=""/> Data Loadding... </div> :
                                        showTrends.map((item, index) => {
                                            return <TrendsItems key={index} item={item} sl={index} />
                                        })
                                }

                                <div className="load_more">
                                    {showTrends.length === trends.length ? null : <button onClick={this.onLoadMore} className="btn primary-btn"> Load more.. </button>}
                                </div>
                            </div>
                            <div className="col-md-3 border-left">
                                <div className="sidebar">
                                    {isCountryLoading ? null : <h3>Countries</h3> }
                                    <ul className="sidebar-link">
                                        {
                                            isCountryLoading ? <div className="data-loading"><img src={ LoaderImg } alt=""/> Data Loadding... </div>:
                                                showCountry.map((item, index) => {
                                                    return <li key={index}><Link to={`/` + item.name} >{item.name}</Link></li>

                                                })
                                        }

                                    </ul>
                                    <div className="load_more">
                                        {
                                            countries ? showCountry.length === countries.length ? null : <button onClick={this.onLoadMoreCountry} className="read-more-link"> Load more.. </button> : null
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
